import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';
import { English } from 'flatpickr/dist/l10n/default.js';
import 'flatpickr/dist/flatpickr.min.css';
import './datepicker.scss';
class Datepicker {
    constructor (element, options) {
        // Settings
        const defaults = {
            initAttr: 'data-datepicker',
            expandedAttr: 'data-datepicker-expanded',
            monthAttr: 'data-datepicker-month',
            endDateAttr: 'data-datepicker-end',
            dateInputsValue: 'date-input',
            calendarValue: 'calendar',
            yearButtonsAttr: 'data-datepicker-year',
            activeAttr: 'data-datepicker-active',
            resetButtonValue: 'reset',
            clearButtonValue: 'clear',
            confirmButtonValue: 'confirm',
            prevButtonValue: 'prev',
            nextButtonValue: 'next'
        };

        this.settings = Object.assign({}, defaults, options);

        // DOM Elements
        this.$root = element;
        this.$calendar = this.$root.querySelector(`[${this.settings.initAttr}="${this.settings.calendarValue}"]`);
        this.$$dateInputs = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.dateInputsValue}"]`);
        this.$$yearButtons = this.$root.querySelectorAll(`[${this.settings.yearButtonsAttr}]`);
        this.$$resetButtons = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.resetButtonValue}"]`);
        this.$$clearButtons = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.clearButtonValue}"]`);
        this.$$confirmButtons = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.confirmButtonValue}"]`);
        this.$$prevButtons = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.prevButtonValue}"]`);
        this.$$nextButtons = this.$root.querySelectorAll(`[${this.settings.initAttr}="${this.settings.nextButtonValue}"]`);

        // Options
        this.datePicker = null;
        this.currentDate = new Date();
        this.expandedVersion = this.$$confirmButtons.length > 0;
        this.endDate = this.$root.getAttribute(this.settings.endDateAttr);
        this.dateConfirmed = false;
        this.prevDates = [];
        this.newDates = ['', ''];
        this.breakpointOptions = {
            showMonth: {
                0: this.$root.getAttribute(this.settings.monthAttr) || 12,
                1024: 2,
                1280: 3,
                1440: 4
            }
        };
        this.visibleMonth = this.getActiveBreakpoint();

        // Locale Mapping
        this.locales = {
            de: German,
            en: English
        };

        this.locale = document.documentElement.lang || 'de';

        if (this.$calendar) {
            this.initialize();
        }
    }

    initialize () {
        this.setupCalendar();

        if (this.$$dateInputs.length > 0) {
            this.getCurrentDates();
        }
        this.setEvents();
    }

    toggleActiveYear (year) {
        this.$$yearButtons.forEach(($yearButton, index) => {
            const buttonsYear = $yearButton.getAttribute(this.settings.yearButtonsAttr);
            $yearButton.setAttribute('data-datepicker-active', year === Number(buttonsYear));
        });
    }

    toggleNavigationStates () {
        const prevMonthAvailable = this.canJumpByMonths(-1);
        const nextMonthAvailable = this.canJumpByMonths(1);

        this.$$prevButtons.forEach($prevButton => {
            $prevButton.disabled = !prevMonthAvailable;
        });

        this.$$nextButtons.forEach($nextButton => {
            $nextButton.disabled = !nextMonthAvailable;
        });
    }

    canJumpByMonths (monthCount) {
        const currentMonth = this.datePicker.currentMonth;
        const currentYear = this.datePicker.currentYear;

        let newMonth = currentMonth + monthCount;

        while (newMonth > 11) {
            newMonth -= 12;
        }
        while (newMonth < 0) {
            newMonth += 12;
        }

        const minDate = this.datePicker.config.minDate ? new Date(this.datePicker.config.minDate) : null;
        const maxDate = this.datePicker.config.maxDate ? new Date(this.datePicker.config.maxDate) : null;

        if (minDate) {
            const minMonth = minDate.getMonth();
            const minYear = minDate.getFullYear();
            if (currentMonth === minMonth && currentYear === minYear && monthCount < 0) {
                return false;
            }
        }

        if (maxDate) {
            const maxMonth = maxDate.getMonth();
            const maxYear = maxDate.getFullYear();

            let limitMonth = currentMonth + this.visibleMonth - 1;
            let limitYear = currentYear;

            while (limitMonth > 11) {
                limitMonth -= 12;
                limitYear++;
            }

            if (maxYear === limitYear && maxMonth <= limitMonth && monthCount > 0) {
                return false;
            }
        }

        return true;
    }

    jumpToYear (targetDate) {
        const jumpTo = targetDate < this.currentDate ? new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1) : targetDate;
        this.datePicker.jumpToDate(jumpTo);
    }

    jumpToMonth (monthCount) {
        this.datePicker.changeMonth(monthCount);
    }

    updateDateInputs (reset, selectedDates) {
        if (!reset) {
            this.getCurrentDates();
        }

        selectedDates.forEach((selectedDate, index) => {
            if (!reset) {
                const date = new Date(selectedDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                this.newDates[index] = `${year}-${month}-${day}`;
                this.$$dateInputs[index].value = `${year}-${month}-${day}`;
            } else if (this.prevDates.length > 0) {
                this.$$dateInputs[index].value = this.prevDates[index];
            }
        });
    }

    isValidDateISO (startDate, endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const isValidStart = !isNaN(start.getTime());
        const isValidEnd = !isNaN(end.getTime());

        if (!isValidStart || !isValidEnd) {
            return false;
        }

        if (end < start) {
            this.datePicker.clear();
        }

        return end >= start;
    }

    updateCalendar () {
        if (this.isValidDateISO(this.newDates[0], this.newDates[1])) {
            const formattedStartDate = this.formatDate(this.newDates[0]);
            const formattedEndDate = this.formatDate(this.newDates[1]);

            // Store the currently visible month and year
            const currentMonth = this.datePicker.currentMonth;
            const currentYear = this.datePicker.currentYear;

            // Create Date objects for the new date values
            const startDate = new Date(this.newDates[0]);
            const endDate = new Date(this.newDates[1]);

            // Calculate the visibsetle period (first day of the current month to the last day of +4 months)
            const minVisibleMonth = new Date(currentYear, currentMonth, 1);
            const maxVisibleMonth = new Date(currentYear, currentMonth + 4, 0); // Last day of the +4th month

            // Check if the start date or end date falls within the visible range
            const isStartDateVisible = startDate >= minVisibleMonth && startDate <= maxVisibleMonth;
            const isEndDateVisible = endDate >= minVisibleMonth && endDate <= maxVisibleMonth;

            // Update the date without changing the displayed month
            this.datePicker.setDate([formattedStartDate, formattedEndDate], true);

            // Jump back to the previously visible month only if one of the conditions is met
            if ((isStartDateVisible || isEndDateVisible) || window.innerWidth < 1024) {
                this.datePicker.changeMonth(0); // Force re-render
                this.datePicker.jumpToDate(new Date(currentYear, currentMonth, 1));
            }
        }
    }

    formatDate (inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    getCurrentDates () {
        this.prevDates = [];
        this.$$dateInputs.forEach($dateInput => {
            this.prevDates.push($dateInput.value);
        });
    }

    setupCalendar () {
        let options;

        if (this.expandedVersion) {
            options = {
                mode: 'range',
                dateFormat: 'd.m.Y',
                inline: true,
                locale: this.locales[this.locale],
                minDate: 'today',
                maxDate: this.endDate,
                disableMobile: true,
                showMonths: this.visibleMonth,
                allowInput: false,
                static: true,
                onYearChange: (selectedDates, dateStr, instance) => {
                    this.toggleActiveYear(instance.currentYear);
                },
                onMonthChange: () => {
                    this.toggleNavigationStates();
                },
                onChange: (selectedDates, dateStr, instance) => {
                    if (selectedDates.length > 1 && this.$$dateInputs.length === 2) {
                        this.updateDateInputs(false, selectedDates);
                        instance._input.blur();
                    }
                },
                onClose: function (selectedDates, dateStr, instance) {
                    instance._input.blur();
                }
            };
        } else {
            options = {
                mode: 'range',
                dateFormat: 'd.m.Y',
                locale: this.locales[this.locale],
                minDate: 'today',
                maxDate: this.endDate,
                static: true,
                onChange: (selectedDates, dateStr, instance) => {
                    if (selectedDates.length > 1 && this.$$dateInputs.length === 2) {
                        this.updateDateInputs(false, selectedDates);
                    }
                }
            };
        }

        this.datePicker = flatpickr(this.$calendar, options);
        const $overlay = document.querySelector('.flatpickr-calendar');

        if ($overlay) {
            $overlay.classList.add('datepicker');
        }

        this.toggleActiveYear(this.datePicker.currentYear);
        this.toggleNavigationStates();

        if (this.expandedVersion) {
            this.calculateHeights();
        } else {
            if (this.$$dateInputs.length === 2) {
                this.newDates = [];
                this.$$dateInputs.forEach($dateInput => {
                    this.newDates.push($dateInput.value);
                    this.updateCalendar();
                });
            }
        }
    }

    calculateHeights () {
        if (window.innerWidth < 1024) {
            const $dayContainer = this.$root.querySelector('.dayContainer');
            const $monthContainer = this.$root.querySelector('.flatpickr-month');
            const $weekdayContainer = this.$root.querySelector('.flatpickr-weekdaycontainer');

            if ($dayContainer && $monthContainer && $weekdayContainer) {
                const getTotalHeight = (element) => {
                    if (!element) return 0;
                    const styles = window.getComputedStyle(element);
                    const height = element.getBoundingClientRect().height;
                    const marginTop = parseFloat(styles.marginTop);
                    const marginBottom = parseFloat(styles.marginBottom);
                    return height + marginTop + marginBottom;
                };

                const dayContainerHeight = getTotalHeight($dayContainer);
                const monthContainerHeight = getTotalHeight($monthContainer);
                const weekdayContainerHeight = getTotalHeight($weekdayContainer);

                this.$root.style.setProperty('--calendar-height', `${dayContainerHeight + monthContainerHeight + weekdayContainerHeight}px`);
            }
        }
    }

    getActiveBreakpoint () {
        const breakpoints = Object.keys(this.breakpointOptions.showMonth)
            .map(bp => parseInt(bp, 10))
            .sort((a, b) => a - b);

        let activeBreakpoint = breakpoints[0];

        for (const bp of breakpoints) {
            if (window.innerWidth >= bp) {
                activeBreakpoint = bp;
            } else {
                break;
            }
        }

        return this.breakpointOptions.showMonth[activeBreakpoint];
    }

    setEvents () {
        this.$$dateInputs.forEach(($dateInput, index) => {
            $dateInput.addEventListener('change', () => {
                this.newDates[index] = $dateInput.value;
                this.updateCalendar();
            });
        });

        this.$$clearButtons.forEach(($clearButton) => {
            $clearButton.addEventListener('click', () => {
                this.$$dateInputs.forEach($dateInput => {
                    $dateInput.value = '';
                });
                this.datePicker.clear();
                this.prevDates = ['', ''];
                this.updateDateInputs(true, this.prevDates);
                this.newDates = this.prevDates;
                this.updateCalendar();
            });
        });

        this.$$resetButtons.forEach(($resetButton) => {
            $resetButton.addEventListener('click', () => {
                this.updateDateInputs(true, this.prevDates);
                this.newDates = this.prevDates;
                this.updateCalendar();
            });
        });

        this.$$confirmButtons.forEach(($confirmButton) => {
            $confirmButton.addEventListener('click', () => {
                this.prevDates = this.newDates;
            });
        });

        this.$$yearButtons.forEach(($yearButton) => {
            $yearButton.addEventListener('click', () => {
                const year = parseInt($yearButton.getAttribute(this.settings.yearButtonsAttr), 10);

                if (window.innerWidth >= 1023) {
                    const targetDate = new Date(year, 0, 1);
                    this.jumpToYear(targetDate);
                    this.toggleActiveYear(year);
                } else {
                    const $$yearInputs = this.$root.querySelectorAll('.numInput');

                    let $firstMatchingInput = null;

                    $$yearInputs.forEach($yearInput => {
                        if ($yearInput.value === String(year) && !$firstMatchingInput) {
                            $firstMatchingInput = $yearInput;
                        }
                    });

                    if ($firstMatchingInput) {
                        $firstMatchingInput.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    }
                }
            });
        });

        this.$$prevButtons.forEach(($prevButton) => {
            $prevButton.addEventListener('click', () => {
                this.jumpToMonth(-1);
            });
        });

        this.$$nextButtons.forEach(($nextButton) => {
            $nextButton.addEventListener('click', () => {
                this.jumpToMonth(1);
            });
        });

        window.resizeHandler.customFunctions.push(() => {
            const visibleMonth = this.getActiveBreakpoint();
            if (visibleMonth !== this.visibleMonth && this.expandedVersion) {
                this.visibleMonth = visibleMonth;
                this.datePicker.set('showMonths', this.visibleMonth);
                this.calculateHeights();
                this.datePicker.jumpToDate(new Date());
            }
        });
    }
}

export { Datepicker };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-datepicker="root"]');
        $$roots.forEach($root => {
            new Datepicker($root); // eslint-disable-line
        });
    }
});
